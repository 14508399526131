import {add} from '@elements/scroll-animations';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-fade-in', function (baseElement) {
        let fadeInElements = [];
        fadeInElements.push(baseElement);
        let slowFade = baseElement.getAttribute('data-fade-in-slow') || false;

        add(
            fadeInElements,
            calculateAnimationProgress(slowFade),
            setAnimationProgress
        );
    });
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(slowFade) {
    return function(element) {
        const start = window.innerHeight / 10 * 9;
        const end = slowFade ? window.innerHeight / 5 : window.innerHeight / 1.5;

        return {
            opacity: 1 - Math.max(
                Math.min(
                    (element.getBoundingClientRect().top - end) / (start - end),
                    1
                ),
                0
            ),
            transform: matchMedia('(max-width: 767px)').matches ?
                Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 30 + 'px'
                : Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 30 + 'px'

        }
    }
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress.opacity;
    element.style.transform = 'translateY(' +progress.transform +')';
}