import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope,onFind} from "@elements/init-modules-in-scope";
import {findIn, find,findAllIn,removeClass, on} from '@elements/dom-utils';

const selectors = {
    base: '.js-render-template',
    template: '.js-render-template__template',
    baseClass: 'js-render-template'
};

export function init () {
    onFind(selectors.base, function (renderTemplates) {
        onEnterViewPort(renderTemplates, function (renderItem) {
            renderTemplate(renderItem)
        }, { offset: 300 });
    });
}

export function renderTemplate(renderItem) {
    let template = findIn(selectors.template,renderItem);
    if(template) {
        renderItem.innerHTML = template.innerHTML;
        removeClass(selectors.baseClass,renderItem);
        template.remove();
        initInScope(renderItem);
    }
}

export function renderAllTemplates() {
    let templates = findAllIn(selectors.base, document.body);
    templates.map(template => {
        renderTemplate(template);
    });
}