import {on, find, findAllIn, findIn, empty} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import fetch from '@elements/fetch';
import Modal from 'bootstrap/js/dist/modal';
import loadScript from '@elements/load-script';

export function init () {
    let kaironAlreadyLoaded = false;

    onFind('.js-ajax-modal', function (element) {
        let modalSelector = element.getAttribute('data-ajax-modal-target');
        let modal = find(modalSelector);

        if (modal) {
            let modalInit = new Modal(modal);

            on('click', function (evt) {
                evt.preventDefault();
                let url = element.getAttribute('data-ajax-modal-content-url');
                let loadKairion = element.getAttribute('data-ajax-modal-load-kairion') || false;

                modalInit.show();

                let request = fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                let alreadyLoaded = false;
                asyncAppend({
                    target: findAllIn('.js-ajax-modal__content', modal),
                    loading: findAllIn('.js-ajax-modal__loading', modal),
                    options: {allowScriptTags: true}
                }, request).then((result) => {
                    console.log(result);

                    if(window.kias && alreadyLoaded) {
                        console.log(kias);
                        window.kias.restartForAjax();
                        console.log("RESTART FOR AJAX");
                    } else {
                        loadScript("https://js.kctag.net/shopnow_protinambh.js").then(() => {
                            console.log("load now");
                            console.log(kias);
                            alreadyLoaded = true;
                            // if(!alreadyLoaded) {
                            //     window.kias.push({ cmd: 'setPageSlots', slots: {
                            //             selector609e1eac0143110017b830e9:'kairionSelector609e1eac0143110017b830e9'
                            //         }});
                            //     window.kias.push({ cmd: 'getSelector', id:'609e1eac0143110017b830e9'});
                            //
                            //     alreadyLoaded = true;
                            // }

                        }).catch(console.error);
                    }
                });
            }, element);

            let close = findIn('.js-ajax-modal__close', modal);
            on('click', function(evt) {
                evt.preventDefault();
                modalInit.hide();
            }, close);

            on('hidden.bs.modal', function () {
                empty(findIn('.js-ajax-modal__content', modal));
            }, modal);
        } else {
            console.warn(`.js-ajax-modal "${modalSelector}" not found`)
        }
    });
}
